import React, { useState } from 'react';
import { Button, CssBaseline, Container, Grid, Paper, Typography, TextField, CircularProgress } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import axios from 'axios';
import DataTable from './DataTable';

const apiUrl = 'https://fuelcelldataapi.azurewebsites.net/api/GetData?code=1JUEwLDz7XH3hbEXwl2DC5-daNKo5XnijF77Kw-xCVjkAzFu55WojA==';

const App = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const handleSearch = async () => {
    if (!fromDate || !toDate) {
      setErrorDialogOpen(true);
      return;
    }

    setLoading(true);
    const fd = fromDate.replace(/-/g , "/");
    const td = toDate.replace(/-/g, "/");

    try {
      const response = await axios.get(`${apiUrl}&from=${fd}&to=${td}`);
      
      setTableData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  const handleReset = () => {
    setFromDate('');
    setToDate('');
    setTableData([]);
  };

  const generateCSVData = () => {
    const header = Object.keys(tableData[0]).join(',');
    const rows = tableData.map((item) => Object.values(item).join(','));
    return header + '\n' + rows.join('\n');
  };

  const handleDownloadCSV = () => {
    if (tableData.length === 0) {
      return;
    }

    const csvData = generateCSVData();
    const encodedURI = encodeURI(csvData);
    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodedURI);
    link.setAttribute('download', 'fuel_cell_data.csv');
    document.body.appendChild(link);
    link.click();
  };
  
  return (
    <Container component="main" maxWidth="100%">
      <CssBaseline />
      <Paper elevation={3}>
        <Typography variant="h4" align="center" gutterBottom style={{ marginTop: '1rem' }}>
          水素ファクトリーデータ出力
        </Typography>
        <Grid container spacing={3} justifyContent="center" marginTop={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="From Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="To Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="center" marginTop={1} marginBottom={2}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="success" onClick={handleReset}>
              Reset
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={handleDownloadCSV}>
              Download CSV
            </Button>
          </Grid>
        </Grid>
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <DataTable data={tableData} />
        )}
      </Paper>
      <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
        <DialogTitle>Not entered</DialogTitle>
        <DialogContent>
          <Typography>日付が指定されていません。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
    
  );
};

export default App;