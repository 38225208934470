import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const formatDate = (dateTimeString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const formattedDateTime = new Date(dateTimeString).toLocaleDateString('ja-JP', options);
  return formattedDateTime;
};

const DataTable = ({ data }) => {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
                        <TableRow>
              <TableCell>日時</TableCell>
              <TableCell>登録日</TableCell>
              <TableCell>登録時刻</TableCell>
              <TableCell>運転状態</TableCell>
              <TableCell>運転可否</TableCell>
              <TableCell>スケジュール運転状態</TableCell>
              <TableCell>アラームコード</TableCell>
              <TableCell>アラームサブコード</TableCell>
              <TableCell>発電電力</TableCell>
              <TableCell>系統電力</TableCell>
              <TableCell>装置状態1</TableCell>
              <TableCell>装置状態2</TableCell>
              <TableCell>故障状態1</TableCell>
              <TableCell>故障状態2</TableCell>
              <TableCell>故障状態3</TableCell>
              <TableCell>直流電圧</TableCell>
              <TableCell>直流電流</TableCell>
              <TableCell>直流電力</TableCell>
              <TableCell>実証用_直流電力</TableCell>
              <TableCell>交流電力</TableCell>
              <TableCell>交流電流</TableCell>
              <TableCell>交流電力</TableCell>
              <TableCell>実証用_交流電力</TableCell>
              <TableCell>周波数</TableCell>
              <TableCell>積算電力量</TableCell>
              <TableCell>日射強度</TableCell>
              <TableCell>気温</TableCell>
              <TableCell>通信異常フラグ</TableCell>
              <TableCell>工場系統（電力）</TableCell>
              <TableCell>工場負荷（電力）</TableCell>
              <TableCell>FC発電（電力）</TableCell>
              <TableCell>蓄電池（充電電力）</TableCell>
              <TableCell>蓄電池（放電電力）</TableCell>
              <TableCell>System状態</TableCell>
              <TableCell>設備状態</TableCell>
              <TableCell>故障保護情報</TableCell>
              <TableCell>蓄電池PCS発電電力（瞬時）</TableCell>
              <TableCell>蓄電池PCS充電電力（瞬時）</TableCell>
              <TableCell>制御モード</TableCell>
              <TableCell>目標値2</TableCell>
              <TableCell>停止SoC（上限下限込み）</TableCell>
              <TableCell>Cレート（上限下限込み）</TableCell>
              <TableCell>拠点蓄電池総容量</TableCell>
              <TableCell>制御種別</TableCell>
              <TableCell>蓄電池SoC</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>dt</TableCell>
              <TableCell>reg_date</TableCell>
              <TableCell>reg_time</TableCell>
              <TableCell>fcv_IsActiveState</TableCell>
              <TableCell>fcv_IsActiveEnable</TableCell>
              <TableCell>fcv_IsEMSInstructionState</TableCell>
              <TableCell>fcv_IsSoftFailure</TableCell>
              <TableCell>fcv_IsHardFailure</TableCell>
              <TableCell>fcv_TransmissionPower</TableCell>
              <TableCell>fcv_GridPower</TableCell>
              <TableCell>pv_DeviceStatus1</TableCell>
              <TableCell>pv_DeviceStatus2</TableCell>
              <TableCell>pv_FailureState1</TableCell>
              <TableCell>pv_FailureState2</TableCell>
              <TableCell>pv_FailureState3</TableCell>
              <TableCell>pv_DCVoltage</TableCell>
              <TableCell>pv_DCCurrent</TableCell>
              <TableCell>pv_DCPower</TableCell>
              <TableCell>pv_DemonstrationDCPower</TableCell>
              <TableCell>pv_ACVoltage</TableCell>
              <TableCell>pv_ACCurrent</TableCell>
              <TableCell>pv_ACPower</TableCell>
              <TableCell>pv_DemonstrationACPower</TableCell>
              <TableCell>pv_Frequency</TableCell>
              <TableCell>pv_IntegralElectricEnergy</TableCell>
              <TableCell>pv_SolarRadiationIntensity</TableCell>
              <TableCell>pv_Temperature</TableCell>
              <TableCell>pv_IsCommunicatonFailure</TableCell>
              <TableCell>distribution_borad_PowerConsumption</TableCell>
              <TableCell>distribution_borad_ElectricitySupply</TableCell>
              <TableCell>distribution_borad_FCPowerGeneration</TableCell>
              <TableCell>distribution_borad_BatteryPowerGeneration</TableCell>
              <TableCell>distribution_borad_LoadDevicePowerConsumption</TableCell>
              <TableCell>battery_SystemStatus</TableCell>
              <TableCell>battery_EquipmentCondition</TableCell>
              <TableCell>battery_FaultProtection</TableCell>
              <TableCell>battery_InstantaneousPowerGeneration</TableCell>
              <TableCell>battery_InstantaneousCharge</TableCell>
              <TableCell>battery_ControlMode</TableCell>
              <TableCell>battery_TargetStorage</TableCell>
              <TableCell>battery_StopSoc</TableCell>
              <TableCell>battery_CRate</TableCell>
              <TableCell>battery_TotalStorageCapacity</TableCell>
              <TableCell>battery_ControlType</TableCell>
              <TableCell>battery_Soc</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.dt}</TableCell>
                <TableCell>{formatDate(row.reg_date)}</TableCell>
                <TableCell>{row.reg_time}</TableCell>
                <TableCell>{row.fcv_IsActiveState}</TableCell>
                <TableCell>{row.fcv_IsActiveEnable}</TableCell>
                <TableCell>{row.fcv_IsEMSInstructionState}</TableCell>
                <TableCell>{row.fcv_IsSoftFailure}</TableCell>
                <TableCell>{row.fcv_IsHardFailure}</TableCell>
                <TableCell>{row.fcv_TransmissionPower}</TableCell>
                <TableCell>{row.fcv_GridPower}</TableCell>
                <TableCell>{row.pv_DeviceStatus1}</TableCell>
                <TableCell>{row.pv_DeviceStatus2}</TableCell>
                <TableCell>{row.pv_FailureState1}</TableCell>
                <TableCell>{row.pv_FailureState2}</TableCell>
                <TableCell>{row.pv_FailureState3}</TableCell>
                <TableCell>{row.pv_DCVoltage}</TableCell>
                <TableCell>{row.pv_DCCurrent}</TableCell>
                <TableCell>{row.pv_DCPower}</TableCell>
                <TableCell>{row.pv_DemonstrationDCPower}</TableCell>
                <TableCell>{row.pv_ACVoltage}</TableCell>
                <TableCell>{row.pv_ACCurrent}</TableCell>
                <TableCell>{row.pv_ACPower}</TableCell>
                <TableCell>{row.pv_DemonstrationACPower}</TableCell>
                <TableCell>{row.pv_Frequency}</TableCell>
                <TableCell>{row.pv_IntegralElectricEnergy}</TableCell>
                <TableCell>{row.pv_SolarRadiationIntensity}</TableCell>
                <TableCell>{row.pv_Temperature}</TableCell>
                <TableCell>{row.pv_IsCommunicatonFailure}</TableCell>
                <TableCell>{row.distribution_borad_PowerConsumption}</TableCell>
                <TableCell>{row.distribution_borad_ElectricitySupply}</TableCell>
                <TableCell>{row.distribution_borad_FCPowerGeneration}</TableCell>
                <TableCell>{row.distribution_borad_BatteryPowerGeneration}</TableCell>
                <TableCell>{row.distribution_borad_LoadDevicePowerConsumption}</TableCell>
                <TableCell>{row.battery_SystemStatus}</TableCell>
                <TableCell>{row.battery_EquipmentCondition}</TableCell>
                <TableCell>{row.battery_FaultProtection}</TableCell>
                <TableCell>{row.battery_InstantaneousPowerGeneration}</TableCell>
                <TableCell>{row.battery_InstantaneousCharge}</TableCell>
                <TableCell>{row.battery_ControlMode}</TableCell>
                <TableCell>{row.battery_TargetStorage}</TableCell>
                <TableCell>{row.battery_StopSoc}</TableCell>
                <TableCell>{row.battery_CRate}</TableCell>
                <TableCell>{row.battery_TotalStorageCapacity}</TableCell>
                <TableCell>{row.battery_ControlType}</TableCell>
                <TableCell>{row.battery_Soc}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DataTable;
